<template>
  <div>
    <main-nav :activeIndex="activeIndex"></main-nav>
    <div class="solution-header-container">
      <div class="solution-header-text">
        <h1 data-caption-delay="0" class="wow fadeInLeft">控制器软件集成开发服务</h1>
        <p data-caption-delay="100" class="wow fadeInRight">为客户提供AUTOSAR基础软件配置集成服务、底层协议栈软件解决方案</p>
        <div data-caption-delay="200" class="solution-text-button wow fadeInUp" @click="linkUrl(chatUrl)">
          <div>立即咨询</div>
        </div>
      </div>
    </div>
    <div class="sw-tab-title" ref="tab" :class="tabFixed ? getTabFixedClass() : ''">
      <div class="container">
        <el-tabs v-model="activeProduct" @tab-click="handleTabClick">
          <el-tab-pane label="基于AUTOSAR CP的控制器软件集成服务" name="1">基于AUTOSAR CP的控制器软件集成服务</el-tab-pane>
          <el-tab-pane label="控制器软件定制开发服务" name="2">控制器软件定制开发服务</el-tab-pane>
          <el-tab-pane label="适配以太网测试的嵌入式软件开发服务" name="3">适配以太网测试的嵌入式软件开发服务</el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div :class="tabFixed ? 'solution-tab-fixed' : ''">
      <div v-if="activeProduct === '1'">
        <section class="solution-intros electric-solution-intro wow fadeInLeft">
          <div class="container">
            <el-row :gutter="30">
              <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <div class="normal-title">解决方案介绍</div>
                <div class="all-intro">
                  <div class="intro-text">
                    <p>东信创智可根据客户不同阶段的开发需求，为客户提供AUTOSAR基础软件的配置集成服务。可提供的服务如下：</p>
                  </div>
                  <div class="intro-text">
                    <ol>
                      <li>基于 Vector CLASSIC AUTOSAR产品 MicroSAR为客户进行配置与集成，功能包括CAN/LIN/Ethernet通信、诊断、网络管理、存储、信息安全等</li>
                      <li>基于 Vector FBL为客户提供Bootloader的配置与集成，实现客户功能需求</li>
                      <li>提供AUTOSAR Microcontroller Abstraction Layer(MCAL)配置服务</li>
                      <li>东信创智可提供Vector CANbedded的配置服务</li>
                    </ol>
                  </div>
                </div>
              </el-col>
              <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
              <div class="intro-picture">
                <img src="../../../assets/images/solution-ecu-soft-intro.png" style="display: block;width: 100%;margin: auto" alt="">
              </div>
            </el-col>
            </el-row>
          </div>
        </section>
        <section class="vehicle-solution-sections wow fadeInRight">
          <div class="container">
            <div class="normal-title">功能特点</div>
            <div class="all-function">
              <ul class="priority-list">
                <li v-for="(item, index) in functionList" :key="index">{{ item.content }}</li>
              </ul>
            </div>
          </div>
        </section>
        <section class="electric-solution-components ecusoft-solution-components ecusoft-solution-components wow fadeInLeft">
          <div class="container" style="height: 230px">
            <div class="normal-title">核心组件</div>
            <div class="solution-components">
              <div class="components-content">
                <div class="components-picture">
                  <img src="../../../assets/images/solution-ecusoft-components-pic.png" alt="">
                </div>
                <div class="components-text">
                  <p>Vector MicroSAR、FBL、CANbedded等</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="solution-application wow fadeInRight">
          <div class="container">
            <div class="normal-title">应用领域</div>
            <div class="all-application">
              <div class="application-content">
                <div class="application-icon">
                  <img src="../../../assets/images/application-picture.svg" alt="">
                </div>
                <div class="application-text">
                  <p>车载控制器AUTOSAR架构嵌入式软件开发</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div v-if="activeProduct === '2'" class="tab-container">
        <section class="solution-intros electric-solution-intro wow fadeInLeft">
          <div class="container">
            <div class="normal-title">解决方案介绍</div>
            <div class="all-intro">
              <div class="intro-text">
                <p>
                  随着汽车行业新四化的趋势不断发展，整车E/E架构面临着巨大的变革，控制器软件面临着适应性更强、快速迭代的挑战，增加了对底层软件多样化适配的需求，如以太网通信、诊断、OTA等，东信创智可依据客户需求提供软件开发咨询及定制代码开发服务，主要包括：</p>
              </div>
              <div class="intro-text">
                <ol>
                  <li>CAN/CANFD/LIN通信软件开发</li>
                  <li>网关路由软件开发，直接报文路由、信号路由、诊断路由</li>
                  <li>SOME/IP软件开发</li>
                  <li>UDS on DOIP诊断软件开发</li>
                  <li>UDS on CAN/CANFD诊断开发</li>
                  <li>UDS on LIN诊断软件开发</li>
                  <li>AUTOSAR NM</li>
                  <li>Bootloader软件开发</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div v-if="activeProduct === '3'" class="tab-container">
        <section class="solution-intros ecusoft-solution-intro wow fadeInLeft">
          <div class="container">
            <div class="normal-title">解决方案介绍</div>
            <div class="all-intro">
              <div class="intro-text">
                <p>伴随着以太网在汽车行业的大量应用，为了帮助汽车厂商客观地评估以太网通信软件的可靠性，配合OPEN Alliance发布的OPEN
                  TC8车载以太网一致性测试规范，东信创智开发了UpperTester及SOME/IP ETS软件模块。</p>
              </div>
              <div class="intro-text">
                <h3>测试规范</h3>
                <ul>
                  <li>OPEN Alliance Automotive Ethernet ECU Test Specification Layer 3-7 Version 3.0</li>
                  <li>Specification of Testability Protocol and Service Primitives AUTOSAR TC Release 1.2.0</li>
                </ul>
                <ol>
                  <li>UpperTester，配合以太网TCP/IP协议一致性测试，适配平台：
                    <ul>
                      <li>Linux</li>
                      <li>QNX</li>
                      <li>Android</li>
                      <li>AUTOSAR CP</li>
                    </ul>
                  </li>
                  <li>SOME/IP ETS，配合SOME/IP一致性测试，适配平台：
                    <ul>
                      <li>vsomeip</li>
                      <li>AUTOSAR CP</li>
                    </ul>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <bzInfo style="margin-top: 0"/>
    <main-footer></main-footer>
  </div>
</template>

<script>
  import mainNav from '@/components/MainNav'
  import mainFooter from '@/components/MainFooter'
  import bzInfo from '@/components/BzInfo'

  export default {
    name: "ecuSoft",
    components: {
      mainNav,
      mainFooter,
      bzInfo
    },
    data() {
      return {
        tabFixed: false,
        activeIndex: '2-2',
        activeProduct: '1',
        functionList: [
          {
            content: '可帮助客户实现Demo级的基础软件集成服务，实现MCAL、通信、诊断、网络管理、OS、RTE、SWC、CDD等模块及功能的目标板快速适配，客户可在此基础上进行功能开发，缩短开发周期'
          }, {
            content: '也可帮助客户实现整车开发各阶段的AUTOASR基础软件配置、开发与集成等需求，满足测试要求，达到量产标准'
          }
        ],
        chatUrl: "https://doc.weixin.qq.com/forms/AOEAXQcgAAkAe4APAaVAD0jHqcmrcFRKf",
      }
    },
    mounted() {
      new this.$wow.WOW().init();
      document.title = "控制器软件集成开发服务 - 解决方案 - 东信创智";
      window.addEventListener("scroll", this.handleScroll);
    },
    destroyed() {
      window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
      linkUrl(url) {
        window.open(url, '_blank') // 在新窗口打开外链接
        // window.location.href =this.indexro;  //在本页面打开外部链接
      },
      handleScroll() {
        if (document.documentElement.clientWidth > 1100) {
          let top = this.$refs.tab.offsetTop - document.documentElement.scrollTop - 70
          if (top < 0 && top > -630) {
            this.tabFixed = !this.tabFixed;
          } else if (document.documentElement.scrollTop > 630) {
            this.tabFixed = true;
          }
        } else {
          let top = this.$refs.tab.offsetTop - document.documentElement.scrollTop - 50
          if (top < 0 && top > -250) {
            this.tabFixed = !this.tabFixed;
          } else if (document.documentElement.scrollTop > 250) {
            this.tabFixed = true;
          }
        }
      },
      getTabFixedClass() {
        if (document.documentElement.clientWidth < 1100) {
          return 'sw-tab-title-mobile-fixed'
        } else {
          return 'sw-tab-title-fixed'
        }
      },
      handleTabClick() {
        if (document.documentElement.clientWidth > 1100 && document.documentElement.scrollTop > 630) {
          document.documentElement.scrollTop = 630
        } else if (document.documentElement.clientWidth < 1100 && document.documentElement.scrollTop > 250) {
          document.documentElement.scrollTop = 250
        }
      }
    }
  }
</script>

<style scoped>

</style>
